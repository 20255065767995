import React from 'react';
import Layout from './index';
import { addLocaleData } from 'react-intl';

import messages from '../data/messages/ru';
// import en from 'react-intl/locale-data/ru';
// import locale_ru from 'react-intl/locale-data/ru';
// import 'react-intl/locale-data/ru';

// addLocaleData(locale_ru);

export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />
);