module.exports = {
    'selectLanguage': 'English',
    'see_the_story': 'Подробнее..',
    'coming_soon':'Подробности\u00a0будут\u00a0позже...',
    'LABEL_DIGITAL_PRODUCTS_DESIGNER':'Дизайнер цифровых продуктов',
    'LABEL_UX_AND_DESIGN':'UХ практики и дизайн',
    'LABEL_HCI':'пользовательских интерфейсов',
    'LABEL_PORTFOLIO':'Портфолио',
    'LABEL_SKILLS':'Профессиональный опыт и\u00a0навыки',
    'LABEL_SKILL1_TITLE':'Исследование и\u00a0анализ',
    'LABEL_SKILL1':' проблем, потребностей, целей, аспектов взаимодействия конечного пользователя с\u00a0продуктом',
    'LABEL_SKILL2_TITLE':'Интервью',
    'LABEL_SKILL2':' с\u00a0заинтересованными сторонами: бизнес, эксперты предметной области, дата-саентисты (если проект на\u00a0базе ML), потенциальные пользователи',
    'LABEL_SKILL3_TITLE':'Описание бизнес-процессов',
    'LABEL_SKILL3':', пользовательских сценариев для\u00a0формирование общего понимания у\u00a0всех участников, Customer Journey Map (CJM)',
    'LABEL_SKILL4_TITLE':'Прототипирование',
    'LABEL_SKILL4':' пользовательских историй (использую Adobe XD или\u00a0Figma)',
    'LABEL_SKILL5_TITLE':'Пользовательское тестирование',
    'LABEL_SKILL5':' интерфейсов, аудит удобства использования',
    'LABEL_SKILL6_TITLE':'Рисование',
    'LABEL_SKILL6':' графических макетов интерфейсов, иконок, пиктограмм… (Adobe\u00a0Illustrator\u00a0&\u00a0Photoshop)',
    'LABEL_SKILL7_TITLE':'Ведение SCRUM проектов',
    'LABEL_SKILL7':' разработки программного обеспечения (с\u00a0помощью Trello, Jira)',
    'LABEL_SKILL8_TITLE':'Сопровождение решения',
    'LABEL_SKILL8':' во\u00a0время разработки, ревью верстки (HTML, CSS)',
    'LABEL_SKILL9_TITLE':'Наставничество и\u00a0поддержка',
    'LABEL_SKILL9':' начинающих дизайнеров (коучинг, обратная связь, поддержка в\u00a0сложных задачах)',
    'LABEL_JOKE':'чем я обычно занимаюсь...',
    'LABEL_SOMETHING_WRONG':'Что-то пошло не&nbsp;так...',
    'LABEL_PAGE_NOT_FOUND':'Ой... Страница не&nbsp;найдена',
    'LABEL_BACK_HOME':' На&nbsp;главную',
    'LABEL_BACK':' Назад',
    'LABEL_GO_UP':' Наверх',
    'LABEL_COPYRIGHT':' Мария Бударевская',
                       
};