import React from "react"

import SEO from "../components/seo"
import About from "../components/about"
import SummaryBlock from "../components/summary-block"

import Layout from "../layouts/ru"
import content from "../content"
import Footer from "../components/footer"

import LandingBlock from "../components/landing-block"

const IndexPage = (props) => {
  const indexProps={name:"Мария Бударевская", ...props}
  console.log('index props', indexProps)
  return (
    <Layout location={props.location} className="my-scroll">
  
    {/* <div> */}
    <SEO title="Maria Budareskaya" />

      <div id="about" className="w-screen grid cols-1 lg:grid-cols-2 bg-gray-750">
        <About {...indexProps}/>
          <SummaryBlock/>
      </div>  
   
    <div className="bg-gray-100 shadow-inner">
      {/* <Stories data={content.getStories('ru')} {...props}/> */}
      {/* <CardPage/>  */}
      <div  id="nextpage" className="divide-y-4 divide-gray-200 shadow">
      {content.getStories('ru').map((story, index) => {
              console.log(`story`, story)
              return <LandingBlock key={index} data={story} />
            })}
      </div>
    </div>
    <Footer  />
    </Layout>
)}

export default IndexPage
